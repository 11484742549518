import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import slugify from "slugify"
import { Row, Col } from "react-bootstrap"

const BlogPosts = () => {
  const data = useStaticQuery(graphql`
    {
      allFeedDevDoneBlog {
        edges {
          node {
            title
            link
          }
        }
        totalCount
        pageInfo {
          itemCount
          pageCount
          perPage
          currentPage
          hasNextPage
          hasPreviousPage
        }
      }
    }
  `)
  return data.allFeedDevDoneBlog.edges.map((post, i) => (
    <Row key={i} className="mt-5 mb-5">
      <Col className="text-center">
        <header className="major">
          <h2>{post.node.title}</h2>
          <strong className="clearfix">
            <Link to={"/blog/" + slugify(post.node.title, { lower: true })}>
              {post.node.title}
            </Link>
          </strong>
        </header>
      </Col>
    </Row>
  ))
}

export default BlogPosts
