import React from "react"
import Image from "../components/image"
import { Row, Col } from "react-bootstrap"
import * as styles from "./sections.module.css"

export default function SmallBanner () {
  return (
    <Row className={styles.sectionBorder + ` pt-5 pb-3`}>
      <Col xs={{ span: 12, order: 2 }} className="text-center p-5">
        <Image src="branding/cover.png" />
      </Col>
    </Row>
  )
}
