import React from "react"
import Seo from "../components/seo"
import SmallBanner from "../components/small-banner"
import Contact from "../components/contact"
import BlogPosts from "../components/blog-posts"
import { Row, Col } from "react-bootstrap"

const IndexPage = () => (
  <>
    <Seo title="Home" />
    <SmallBanner />
    <Row className="mt-5">
      <Col className="text-center">
        <h1>Welcome to the Dev Done Blog</h1>
        <p>
          Here are a collection of posts we've published over on Medium.com,
          please enjoy and subscribe.
        </p>
      </Col>
    </Row>

    <BlogPosts />
    <Contact />
  </>
)

export default IndexPage
